import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  isLoading: boolean = false;
  constructor(
    private readonly loaderService: CommonService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // Move the subscription into ngOnInit
    this.loaderService.isLoading$.subscribe((loading: boolean) => {
      this.isLoading = loading;
      this.cdr.detectChanges();
    });
  }
}
