import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

export enum SIPComponent {
  Home,
  Details,
  Confirm,
  Result,
}

export interface SipDetails {
  planName: string;
  frequency: string;
  startDate: string;
  period: number;
  installmentAmt: number | null;
  accumulatedGold: number;
  endDate?: string;
  url:string | null
}

export interface SipDetailsObj {
  planName: string;
  frequency: string;
  startDate: string;
  period: number;
  installmentAmt: number;
}

export const freqMap: { [key: string]: string } = {
  WEEK: 'Weekly',
  MNTH: 'Monthly',
  QURT: 'Quarterly'
};

@Injectable({
  providedIn: 'root',
})
export class SipService {
  constructor(
    private readonly http: HttpClient
  ) // private readonly messageService: MessageService
  {}
  private readonly currentState = new BehaviorSubject<SIPComponent>(SIPComponent.Home);
  private readonly sipDetailsSubject = new BehaviorSubject<SipDetails | null>(null);
  private readonly eMandateStatus = new BehaviorSubject<boolean>(false);
  private readonly sipId = new BehaviorSubject<number | null>(null);

  getCurrentState(): BehaviorSubject<SIPComponent> {
    return this.currentState;
  }

  changeState(newState: SIPComponent) {
    this.currentState.next(newState);
  }

  setSipDetails(details: SipDetails) {
    this.sipDetailsSubject.next(details);
  }

  setSipId(id: number | null) {
    this.sipId.next(id);
  }
  getSipId() {
    return this.sipId.asObservable();
  }
  getSipDetails() {
    return this.sipDetailsSubject.asObservable();
  }

  getSipData() {
    return this.http.get(`${environment.digigoldbe}/sip`);
  }

  setEMandateStatus(status: boolean) {
    this.eMandateStatus.next(status);
  }

  getEMandateStatus() {
    return this.eMandateStatus.asObservable();
  }

  saveSipData(payload: SipDetailsObj) {
    return this.http.post(`${environment.digigoldbe}/sip`, payload);
  }

  getSipConfig() {
    return this.http.get(`${environment.digigoldbe}/sip/config`);
  }

  initiateSIP(sipId: number) {
    return this.http.get(
      `${environment.digigoldbe}/sip/initiate?sipId=${sipId}`
    );
  }
}
